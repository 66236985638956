import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Switch,
  TextField,
  Select,
  MenuItem,
  Grid,
  Chip,
  colors,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getUserList,
  updateUserList,
  getPanelComment,
  getPanelUserList,
  getActiveDeactivePanel,
  getAgentList,
  getAgentData,
  getAssignAgentList,
  updateUserStatus,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  getOzontelSkillsData,
  postMoveAgent,
} from "../../actions/TechSupportActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../loader";
import EditUser from "../SubAdmin/editUser";
import { useHistory } from "react-router";
import { FormatColorText, MessageRounded } from "@material-ui/icons";
import PanelUserComment from "../Comments/panelComments";
import { genratePanelUserFiltr } from "../../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ADDUSER_EXECUTIVE_TYPES } from "../constant";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 2,
};

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: 600,
    },
    body: {
      fontSize: 12,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 18,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getLeadSource: any;
  getPanelUserList: any;
  lead_source: any;
  updateUserList: any;
  getUserList: any;
  users: any;
  getPanelComment: any;
  panelUserList: any;
  panelComments: any;
  getActiveDeactivePanel: any;
  updateUserStatus: any;
  getAgentList: any;
  loading: boolean;
  userloading: boolean;
  getAgentData: any;
  getOzontelSkillsData: any;
  assigneUserList: any;
  getAssignAgentList: any;
  agentdata: any;
  ozontelskilldata: any;
  getPanelUserListSalesLead: any;
  salesLeadList: any;
  getPanelUserListTeamLead: any;
  teamLeadList: any;
  userDetails: any;
  id: any;
  postMoveAgent:any;
}

const TechManageUser: React.FC<Props> = ({
  updateUserList,
  getUserList,
  users,
  getPanelComment,
  panelComments,
  updateUserStatus,
  getAssignAgentList,
  assigneUserList,
  getOzontelSkillsData,
  ozontelskilldata,
  loading,
  userloading,
  salesLeadList,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  teamLeadList,
  userDetails,
  postMoveAgent,
  id,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [userGroup, setUserGroup] = useState("none");
  const [userStatus, setUserStatus] = useState("none");
  const [executiveTypeFilter, setExecutiveTypeFilter] = useState("none");
  const [executiveTypeTable, setExecutiveTypeTable] = useState("none");
  const [callFrominBulk, setCallFrominBulk] = useState("none");
  const [callFromTable, setCallFromTable] = useState<string>("");
  const [panelUserId, setPanelUserId] = useState("none");
  const [userName, setUserName] = useState<any>([]);
  const [ozontelNameFilter, setozontelFilter] = useState<any>([]);
  const [ozontelNameTable, setozontelNameTable] = useState<any>([]);
  const [ozontelNameUpdated, setozontelNameUpdated] = useState<any>([]);
  const [ozontelId, setOzontelId] = useState<any>([]);
  const [ozontelIdTable, setOzontelIdTable] = useState<any>([]);
  const [ozontelIdUpdated, setOzontelIdUpdated] = useState<any>([]);
  const [isActive, setIsActive] = useState(false);
  const [isActiveBulkUpdate, setIsActiveBulkUpdate] = useState("none");
  const [is_outbound, setIsOutBond] = useState(false);
  const [editUser, setEditUser] = useState<any>({});
  const [editUserDetails, setEditUserDetails] = useState<any>({});
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [user_name, setUser_name] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>("");
  const [salesLead, setSalesLead] = useState<any>("");
  const [userId, setUserId] = useState<any>("");
  const [isEnableUpdateBtn, setIsEnableUpdateBtn] = useState(true);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [assignName, setAssignName] = useState<any>([]);
  const [assignId, setAssignId] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [openAgentModal, setOpenAgentModal] = useState<boolean>(false);
  const [userNameAgent, setUserNameAgent] = useState<any>("");
  const [agentType, setAgentType] = useState<any>("");

  const handleCloseAgentModal = () => {
    setOpenAgentModal(false);
    setUserNameAgent("");
    setAgentType("");
};

  const handleAgent = async (e: any) => {
    e.preventDefault();
    const data={
      username:userNameAgent,
      ctc_from:agentType,
    };
    await postMoveAgent(data);
    setUserNameAgent("");
    setAgentType("");
    setOpenAgentModal(false);
  };

  useEffect(() => {
    // getOzontelSkillsData();
    // getPanelUserListTeamLead(`?usergroup=CustomerLead`);
    // getAssignAgentList("?usergroup=CustomerExecutive");
    // getPanelUserListSalesLead(`?usergroup=SalesLead`);
    getUserList();
  }, []);

  useEffect(() => {
    setPanelUserId(editUser?.id);
    setCallFromTable(editUser?.ctc_from);
    setIsOutBond(editUser?.is_outbound);
    setIsActive(editUser?.status);
    setUserStatus(editUser.status);
  }, [editUser]);

  useEffect(() => {
    if (selectedUsers) {
      if (
        callFrominBulk != "none" ||
        ozontelIdUpdated.length > 0 ||
        isActiveBulkUpdate.toString() == "true" ||
        isActiveBulkUpdate.toString() == "false"
      ) {
        setIsEnableUpdateBtn(false);
      } else {
        setIsEnableUpdateBtn(true);
      }
    } else {
      setIsEnableUpdateBtn(true);
    }
  }, [ozontelIdUpdated, isActiveBulkUpdate, callFrominBulk, selectedUsers]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = users.links.next;
      getUserList(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = users.links.previous;
      getUserList(url.includes("?") ? url.substring(url.indexOf("?")) : "");
    }
    setPage(newPage);
  };

  const handleEditUser = (user: any) => {
    setOpenUserModal(true);
    setEditUserDetails(user);
  };
  const handleClick = (id: number) => {
    setopenModal(true);
    getPanelComment(id);
  };

  /* This funciton  is used for filter */
  const filterPanelUser = () => {
    setDisableFilterBtn(true);
    const body: any = {
      usergroup: userGroup,
      is_active: userStatus,
      username: user_name,
      executive_type: executiveTypeFilter != "none" ? executiveTypeFilter : "",
      tl: teamLead,
      sl: salesLead,
      ozontel_skills: ozontelId,
      phone,
      email,
    };
    //localStorage.setItem("PANELUSERFILTER", JSON.stringify(body));
    const url = genratePanelUserFiltr(body).substring(2);
    getUserList(`?${url}`);
  };

  /* This function is used for update single user */
  const handleUser = async () => {
    let body: any = {
      id: panelUserId,
      status: isActive.toString(),
      ctc_from: callFromTable ? callFromTable : null,
      is_outbound: is_outbound.toString(),
      tl: assignId,
      executive_type: executiveTypeTable != "none" ? executiveTypeTable : null,
      ozontel_skills: ozontelIdTable ? ozontelIdTable : null,
    };
    await updateUserList(body);
    setEditUser({});
    getUserList();
  };

  /*This function is used for update bulk users */
  const handleUpdateStatus = async () => {
    let bulkUpdateData: any = [];
    selectedUsers &&
      selectedUsers.forEach((item: any) => {
        bulkUpdateData.push({
          id: item,
          status: isActiveBulkUpdate.toString(),
          ozontel_skills: ozontelIdUpdated ? ozontelIdUpdated : null,
          ctc_from: callFrominBulk == "none" ? null : callFrominBulk,
        });
      });
    await updateUserList(bulkUpdateData, "array");
    setEditUser({});
    getUserList();
    handleClose();
  };
  const handleClose = () => {
    history.push("/dashboard/ts/users");
  };

  const handleEditMember = (user: any) => {
    setUserName(user?.asigned[0]?.user__username);
    setEditUser(user);
  };

  /*This function is used for disable filter button */
  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={10}>
            <h2>Manage Users</h2>
          </Grid>
        </Grid>

        <br />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="username_filter"
              onChange={(event, newValue) => {
                let sourceusername: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceusername = obj.map((item: any) => item?.id);
                }
                setUser_name(sourceusername);
              }}
              options={assigneUserList?.results || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option?.id && option?.username}
              getOptionDisabled={(option: any) => {
                return user_name?.includes(option?.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAssignAgentList(
                    `?code=${newInputValue}&data_required=all`
                  );
                }, 1000);
                if (newInputValue?.length === 0) {
                  setUser_name([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Username"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="usergroup_filter"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setUserGroup(obj.value);
                }
              }}
              options={[
                {
                  text: "CustomerExecutive",
                  value: "CustomerExecutive",
                },
                {
                  text: "SupportExecutive",
                  value: "SupportExecutive",
                },
                {
                  text: "VerificationExecutive",
                  value: "VerificationExecutive",
                },
                {
                  text: "Doctor",
                  value: "Doctor",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="User Group"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ExecutiveTypeFilter"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setExecutiveTypeFilter(obj.value);
                }
              }}
              options={[
                {
                  text: "RCA",
                  value: "RCA",
                },
                {
                  text: "Resampling",
                  value: "Resampling",
                },
                {
                  text: "Report",
                  value: "Report",
                },
                {
                  text: "Refund",
                  value: "Refund",
                },
                {
                  text: "Admin",
                  value: "Admin",
                },
                {
                  text: "Discount",
                  value: "Discount",
                },
                {
                  text: "Ticketing",
                  value: "Ticketing",
                },
                {
                  text: "Consultation",
                  value: "Consultation",
                },
                {
                  text: "North Zone",
                  value: "North Zone",
                },
                {
                  text: "South Zone",
                  value: "South Zone",
                },
                {
                  text: "West Zone",
                  value: "West Zone",
                },
                {
                  text: "East Zone",
                  value: "East Zone",
                },
                {
                  text: "CEGenomics",
                  value: "CEGenomics",
                },
                {
                  text: "Inbound",
                  value: "Inbound",
                },
                {
                  text: "Outbound",
                  value: "Outbound",
                },
                {
                  text: "LTV",
                  value: "LTV",
                },
                {
                  text: "CoCal",
                  value: "CoCal",
                },
                {
                  text: "Dialer",
                  value: "Dialer",
                },
                {
                  text: "OBH1",
                  value: "OBH1",
                },
                {
                  text: "OBH2",
                  value: "OBH2",
                },
                {
                  text: "Lab",
                  value: "Lab",
                },
                {
                  text: "CentralLab",
                  value: "CentralLab",
                },
                {
                  text: "Escalation",
                  value: "Escalation",
                },
                {
                  text: "ChatAndEmail",
                  value: "ChatAndEmail",
                },
                {
                  text: "CASH",
                  value: "CASH",
                },
                {
                  text: "NONCASH",
                  value: "NONCASH",
                },
                {
                  text: "ABM/ASM",
                  value: "ABM/ASM",
                },
                {
                  text: "SE/TM",
                  value: "SE/TM",
                },
                {
                  text: "RBM/RSM",
                  value: "RBM/RSM",
                },
                {
                  text: "ZSM",
                  value: "ZSM",
                },
                {
                  text: "AGM SALES",
                  value: "AGM SALES",
                },
                {
                  text: "GM SALES",
                  value: "GM SALES",
                },
                {
                  text: "UpgradedCE",
                  value: "UpgradedCE",
                },
                {
                  text: "B2B_CX",
                  value: "B2B CX",
                },
                {
                  text: "InvLabDr",
                  value: "Inventory Lab Dr.",
                },
                {
                  text: "InvLabService",
                  value: "Inventory Lab Service",
                },
                {
                  text: "InvLabHead",
                  value: "Inventory Lab Head",
                },
                {
                  text: "SuperAdmin",
                  value: "SuperAdmin",
                },
                {
                  text: "BML",
                  value: "BML",
                },
                {
                  text: "standard",
                  value: "Standard",
                },
                {
                  text: "corporate_app",
                  value: "Corporate App",
                },
                {
                  text: "FINANCE",
                  value: "Finance",
                },
                {
                  text: "OLCP",
                  value: "Olc Partner",
                },
                {
                  text: "olc",
                  value: "olc",
                },
                {
                  text: "DSA",
                  value: "DSA",
                },
                {
                  text: "Manager",
                  value: "Manager",
                },
                {
                  text: "AreaManager",
                  value: "AreaManager",
                },
                {
                  text: "PPMC",
                  value: "PPMC",
                },
                {
                  text: "DRM",
                  value: "DRM",
                },
                {
                  text: "Contract",
                  value: "Contract",
                },
                {
                  text: "Auditor",
                  value: "Auditor",
                },
                {
                  text: "Genomics",
                  value: "Genomics",
                },
                {
                  text: "GenomicsAdmin",
                  value: "GenomicsAdmin",
                },
                {
                  text: "Routine",
                  value: "Routine",
                },
                {
                  text: "RoutineAdmin",
                  value: "RoutineAdmin",
                },
                {
                  text: "billing",
                  value: "billing",
                },
                {
                  text: "phlebos",
                  value: "phlebos",
                },
                {
                  text: "SampleAccessioning",
                  value: "SampleAccessioning",
                },
                {
                  text: "Others",
                  value: "Others",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Executive Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4} md={2}>
            <Select
              id="ExecutiveTypeFilter"
              className="ExecutiveTypes"
              variant="outlined"
              defaultValue={executiveTypeFilter}
              value={executiveTypeFilter}
              style={{
                width: "100%",
                minWidth: "150px",
                textAlign: "left",
                height: "40px",
              }}
              onChange={(e) => setExecutiveTypeFilter(e.target.value as string)}
            >
              <MenuItem value={"none"} disabled>
                Executive Type
              </MenuItem>
              {ADDUSER_EXECUTIVE_TYPES &&
                ADDUSER_EXECUTIVE_TYPES.map((exe_type: any) => {
                  return (
                    <MenuItem value={exe_type.value}>{exe_type.value}</MenuItem>
                  );
                })}
            </Select>
          </Grid> */}
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="user_status_filter"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setUserStatus(obj.value);
                }
              }}
              options={[
                {
                  text: "Active",
                  value: "true",
                },
                {
                  text: "Inactive",
                  value: "false",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Active/InActive"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="team_lead_filter"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setTeamLead(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={teamLeadList?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.id && option?.user?.username
              }
              loading={loading}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListTeamLead(
                    `?code=${newInputValue}&usergroup=CustomerLead`
                  );
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Team Lead"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="sales_lead_filter"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setSalesLead(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={salesLeadList?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.id && option?.user?.username
              }
              loading={loading}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListSalesLead(
                    `?code=${newInputValue}&usergroup=SalesLead`
                  );
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Sales Lead"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="skill_filter"
              onChange={(event, newValue) => {
                if (newValue) {
                  let userARR: any = [];
                  let sourceId: any = [];
                  newValue.map((val: any) => {
                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                    userARR.push(obj);
                    sourceId.push(obj.id);
                  });
                  setozontelFilter(userARR);
                  setOzontelId(sourceId);
                }
              }}
              value={ozontelNameFilter}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getOzontelSkillsData(`?search=${newInputValue}`);
                }, 500);
              }}
              multiple
              disableClearable
              disableCloseOnSelect
              limitTags={1}
              options={ozontelskilldata?.results || []}
              freeSolo
              blurOnSelect
              getOptionDisabled={(option: any) => {
                return ozontelId.includes(option.id);
              }}
              getOptionLabel={(option: any) =>
                String(option?.id) && String(option?.name)
              }
              renderTags={(value: string[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    key={index}
                    variant="default"
                    color="primary"
                    label={String(option?.id) && String(option?.name)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ozontel Skill"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  className="input"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="email"
              type="email"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterPanelUser}
              disabled={disableFilterBtn}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ts/users")}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="success"
              style={{ height: "40px", marginLeft: "10px" }}
              fullWidth
              onClick={() => setOpenAgentModal(true)}
            >
              Move Agent
            </Button>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "10px", color: "red", }}

        >
          <Grid item xs={12} sm={12}>
            Note:
            <small>
              <ul style={{ paddingLeft: "15px" }}>
                <li>You can filter by usergroup CE, SE, VE, Doctor.</li>
              </ul>
            </small>
          </Grid>
        </Grid>

        <br />
        {selectedUsers.length > 0 && (
          <div>
            <h2>Update Status</h2>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="status_bulk_update"
                  variant="outlined"
                  value={isActiveBulkUpdate}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setIsActiveBulkUpdate(e.target.value as any)}
                >
                  <MenuItem disabled value={"none"}>
                    Active/InActive
                  </MenuItem>
                  <MenuItem value={"true"}>Active</MenuItem>
                  <MenuItem value={"false"}>Deactive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="skill_bulk_update"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let skillNameArr: any = [];
                      let skillIdArr: any = [];
                      newValue.map((val: any) => {
                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                        skillNameArr.push(obj);
                        skillIdArr.push(obj.id);
                      });
                      setozontelNameUpdated(skillNameArr);
                      setOzontelIdUpdated(skillIdArr);
                    }
                  }}
                  value={ozontelNameUpdated}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getOzontelSkillsData(`?search=${newInputValue}`);
                    }, 500);
                  }}
                  multiple
                  disableClearable
                  disableCloseOnSelect
                  limitTags={1}
                  options={ozontelskilldata?.results || []}
                  freeSolo
                  blurOnSelect
                  getOptionDisabled={(option: any) => {
                    return ozontelIdUpdated.includes(option.id);
                  }}
                  getOptionLabel={(option: any) =>
                    String(option?.id) && String(option?.name)
                  }
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        key={index}
                        variant="default"
                        color="primary"
                        label={String(option?.id) && String(option?.name)}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ozontel Skill"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      className="input"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  id="call_from_bulk_update"
                  className="input"
                  name="callFrom"
                  variant="outlined"
                  value={callFrominBulk}
                  style={{
                    width: "100%",
                    minWidth: "150px",
                    textAlign: "left",
                    height: "40px",
                  }}
                  onChange={(e) => setCallFrominBulk(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    Call from
                  </MenuItem>
                  <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                  <MenuItem value={"KNO"}>KNOWLARITY</MenuItem>
                  <MenuItem value={"TATATELE"}>TATA TELE</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ height: "40px", marginLeft: "10px" }}
                  fullWidth
                  onClick={handleUpdateStatus}
                  disabled={isEnableUpdateBtn}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "40px", marginLeft: "10px" }}
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/users")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
            {userloading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      <input
                        type="checkbox"
                        className="input"
                        name="selectall"
                        id="selectall"
                        onChange={(e) =>
                          e.target.checked
                            ? setSelectedUsers(
                              users.results &&
                              users.results.length > 0 &&
                              users.results.map((user: any) => {
                                return user.id && user.id;
                              })
                            )
                            : setSelectedUsers([])
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">Edit User</StyledTableCell>
                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">UserName</StyledTableCell>
                    <StyledTableCell align="center">Mobile Number</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">UserGroup</StyledTableCell>
                    <StyledTableCell align="center">
                      Executive Type
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Active/InActive
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Click To Call
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Ozontel Skills
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Assigned TL
                    </StyledTableCell>
                    <StyledTableCell align="center">Call from</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.results &&
                    users.results.length > 0 &&
                    users.results.map((user: any) => {
                      return (
                        <StyledTableRow key={user.username}>
                          <StyledTableCell component="th" scope="row">
                            <input
                              type="checkbox"
                              name="selectall"
                              id="selectall"
                              checked={selectedUsers.find(
                                (setUser: any) => setUser === user.id
                              )}
                              onChange={(e) =>
                                !e.target.checked
                                  ? setSelectedUsers(
                                    selectedUsers.filter(
                                      (setUser: any) => setUser !== user.id
                                    )
                                  )
                                  : setSelectedUsers((prev: any) => [
                                    ...prev,
                                    user.id,
                                  ])
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleUser()}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleEditMember(user)}
                              >
                                Edit
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* <Link
                            to={`/dashboard/ts/users/editUser`}
                          > */}
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() =>
                                history.push(
                                  `/dashboard/ts/userDetails/${user?.id}/${user?.username}`
                                )
                              }
                            >
                              Edit User
                            </Button>
                            {/* </Link> */}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <MessageRounded
                                onClick={() => handleClick(user.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.email}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.usergroup}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Select
                                className="ExecutiveTypes"
                                name="ExecutiveTypes"
                                variant="outlined"
                                defaultValue={user?.executive_type}
                                value={
                                  executiveTypeTable !== "none"
                                    ? executiveTypeTable
                                    : user.executive_type
                                }
                                style={{
                                  width: "100%",
                                  minWidth: "150px",
                                  textAlign: "left",
                                  height: "40px",
                                }}
                                onChange={(e) =>
                                  setExecutiveTypeTable(
                                    e.target.value as string
                                  )
                                }
                              >
                                <MenuItem value={"none"} disabled>
                                  Executive Type
                                </MenuItem>
                                {ADDUSER_EXECUTIVE_TYPES &&
                                  ADDUSER_EXECUTIVE_TYPES.map((exe_type: any) => {
                                    return (
                                      <MenuItem value={exe_type.value}>
                                        {exe_type.value}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            ) : (
                              <p>{user?.executive_type}</p>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Switch
                                defaultChecked={user?.status}
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={user?.status}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Switch
                                defaultChecked={user?.is_outbound}
                                checked={is_outbound}
                                onChange={(e) => setIsOutBond(e.target.checked)}
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={user?.is_outbound}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser?.username === user?.username ? (
                              <Autocomplete
                                id="ozontel_skill_table"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let userARR: any = [];
                                    let sourceId: any = [];
                                    newValue.map((val: any) => {
                                      let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                      );
                                      userARR.push(obj);
                                      sourceId.push(obj.id);
                                    });
                                    setozontelNameTable(userARR);
                                    setOzontelIdTable(sourceId);
                                  }
                                }}
                                // value={ozontelNameTable}
                                defaultValue={ozontelNameTable}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    getOzontelSkillsData(
                                      `?search=${newInputValue}`
                                    );
                                  }, 500);
                                }}
                                multiple
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={ozontelskilldata?.results || []}
                                freeSolo
                                blurOnSelect
                                getOptionDisabled={(option: any) => {
                                  return ozontelIdTable.includes(option.id);
                                }}
                                getOptionLabel={(option: any) =>
                                  String(option?.id) && String(option?.name)
                                }
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={
                                        String(option?.id) &&
                                        String(option?.name)
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Ozontel Skill"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <div>
                                {user?.skills.map((skill: any) => {
                                  return (
                                    <p
                                      key={skill?.id}
                                      style={{ margin: "0.5rem auto" }}
                                    >
                                      {skill?.name}
                                    </p>
                                  );
                                })}
                              </div>
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Autocomplete
                                id="assign tl"
                                key={userName}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    // updateZone(obj.id, booking.pk);
                                    setUserId(obj?.id);
                                  }
                                  // setUserName(userARR);
                                  // setUserId(sourceId);
                                }}
                                defaultValue={{ username: userName }}
                                // multiple
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={teamLeadList?.results || []}
                                freeSolo
                                blurOnSelect
                                // getOptionDisabled={(option: any) => {
                                //   const scId = user.asigned.map(
                                //     (data: any) => data.id
                                //   );
                                //   return scId.includes(option.id);
                                // }}
                                // inputValue={userName}
                                onInputChange={(event, newInputValue) => {
                                  // setUserName(newInputValue)
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    if (event !== null)
                                      getPanelUserListTeamLead(
                                        `?code=${newInputValue}&usergroup=CustomerLead`
                                      );
                                  }, 1000);
                                }}
                                getOptionLabel={(option: any) =>
                                  option?.user?.username
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="input"
                                    placeholder="Assigned TL"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    size="small"
                                  />
                                )}
                              />
                            ) : (
                              <div>
                                {user.asigned.map((user: any) => {
                                  return (
                                    <p
                                      key={user.id}
                                      style={{ margin: "0.5rem auto" }}
                                    >
                                      {user.user__username}
                                    </p>
                                  );
                                })}
                              </div>
                            )}
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Autocomplete
                                id="username"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let userARR: any = [];
                                    let sourceId: any = [];
                                    newValue.map((val: any) => {
                                      let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                      );
                                      userARR.push(obj);
                                      sourceId.push(obj.id);
                                    });
                                    setAssignName(userARR);
                                    setAssignId(sourceId);
                                  }
                                }}
                                value={assignName}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    getPanelUserListTeamLead(
                                      `?code=${newInputValue}&usergroup=CustomerLead`
                                    );
                                  }, 1000);
                                }}
                                multiple
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={teamLeadList?.results || []}
                                freeSolo
                                blurOnSelect
                                getOptionDisabled={(option: any) => {
                                  const scId = user?.asigned?.map(
                                    (data: any) => data?.id
                                  );
                                  return scId.includes(option?.id);
                                }}
                                getOptionLabel={(option: any) =>
                                  option?.user && option?.user?.username
                                }
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={
                                        option?.user && option?.user?.username
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Assigned TL"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <div>
                                {user?.asigned?.map((user: any) => {
                                  return (
                                    <p
                                      key={user?.id}
                                      style={{ margin: "0.5rem auto" }}
                                    >
                                      {user?.user__username}
                                    </p>
                                  );
                                })}
                              </div>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Select
                                className="input"
                                name="callFrom"
                                variant="outlined"
                                defaultValue={user.ctc_from}
                                value={
                                  callFromTable !== "none"
                                    ? callFromTable
                                    : user.ctc_from
                                }
                                style={{
                                  width: "100%",
                                  minWidth: "150px",
                                  textAlign: "left",
                                  height: "40px",
                                }}
                                onChange={(e) =>
                                  setCallFromTable(e.target.value as string)
                                }
                              >
                                <MenuItem value={"none"} disabled>
                                  Call from
                                </MenuItem>
                                <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                                <MenuItem value={"KNO"}>KNOWLARITY</MenuItem>
                                <MenuItem value={"TATATELE"}>
                                  TATA TELE
                                </MenuItem>
                              </Select>
                            ) : (
                              user.ctc_from
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={2}
                    count={users.count || 0}
                    rowsPerPage={users.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
          <PanelUserComment
            openModal={openModal}
            setopenModal={setopenModal}
            // panelComments={panelComments}
            loading={loading} id={id} />
          <EditUser
            userDetails={editUserDetails}
            setOpenUserModal={setOpenUserModal}
            openUserModal={openUserModal}
            loading={loading}
          />
          <Modal
            open={openAgentModal}
            onClose={handleCloseAgentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="Name"
                    className="input"
                    type="text"
                    value={userNameAgent}
                    label="User Name"
                    variant="outlined"
                    onChange={(e) => setUserNameAgent(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Select
                    className="input"
                    name="agent_type"
                    variant="outlined"
                    value={agentType}
                    label="Type"
                    style={{ width: "100%",height: "40px", }}
                    onChange={(e) => setAgentType(e.target.value as any)}
                  >
                    <MenuItem value={"ameyo"}>AMEYO</MenuItem>
                    <MenuItem value={"ozonetel"}>OZONETEL</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAgent}
                    disabled={agentType===""|| userNameAgent===""}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  users: state.TechSupportReducer.users,
  loading: state.TechSupportReducer.loading,
  userloading: state.TechSupportReducer.userloading,
  panelComments: state.TechSupportReducer.panelComments,
  panelUserList: state.TechSupportReducer.panelUserList,
  agentdata: state.TechSupportReducer.agentdata,
  ozontelskilldata: state.TechSupportReducer.ozontelskilldata,
  assigneUserList: state.TechSupportReducer.assigneUserList,
  salesLeadList: state.TechSupportReducer.salesLeadList,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  userDetails: state.TechSupportReducer.userDetails,
});

export default connect(mapStateToProps, {
  getUserList,
  updateUserList,
  getPanelComment,
  getPanelUserList,
  getActiveDeactivePanel,
  getAgentList,
  getAssignAgentList,
  getAgentData,
  getOzontelSkillsData,
  updateUserStatus,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  postMoveAgent,
})(TechManageUser);
